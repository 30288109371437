<template>
  <div class="pa-12">
    <!-- <data-cards></data-cards> -->
    <span class="text-h5">Welcome to the Y.SPOT Village-by-CA !</span>
    <div class="ghost-vertical-separator"></div>
  </div>
</template>

<script>
// import DataCards from '../components/DataCards.vue';
// import RoomCharts from '../components/RoomCharts.vue';

export default {
  name: 'Home',
  components: {
    // DataCards,
    // RoomCharts,
  },

  created() {
    this.$store.dispatch('setHeaderTitle', '');
  },

};
</script>

<style scoped>
  .ghost-vertical-separator {
    width: 100%;
    height: 12px;
    background-color: transparent;
    margin: 24px 0;
  }
</style>
