import Vue from 'vue';
import Auth from '@aws-amplify/auth';
import VueRouter from 'vue-router';
import { AWSEventBus } from '@bag-era/cognito-authentication';
import Home from '../views/Home.vue';
import cognitoconfig from '../aws-config';
import store from '../store';

Vue.use(VueRouter);
Auth.configure(cognitoconfig);

const appMetaTitle = 'Y.Spot Village - Stone Edge Building';
const urlFormatter = {
  default: '/',
  home: '/home',
  badgeUserList: '/badgesusers',
  badgeUserFormURlRoot: '/badgesusers/',
  badgeUserFormNew: '/badgesusers/new/',
  badgeUserFormEdit: (itemId) => `/badgesusers/edit/${itemId}`,
  doorList: '/locations/doors',
  doorListCollective: '/locations/doors/collective',
  doorListOffice: '/locations/doors/office',
  ruleList: '/rules',
  ruleFormURlRoot: '/rules/',
  ruleFormNew: '/rules/new/',
  ruleFormEdit: (itemId) => `/rules/edit/${itemId}`,
  stationLocationList: '/stationslocations',
  stationLocationFormURlRoot: '/stationslocations/',
  stationLocationFormNew: '/stationslocations/new/',
  stationLocationFormEdit: (itemId) => `/stationslocations/edit/${itemId}`,
};

const ifAuthenticated = (to, from, next) => {
  document.title = appMetaTitle;
  if (store.getters.isAuthenticated) {
    next();
    return;
  }
  next('/login');
};

const loginMetaSettings = (to, from, next) => {
  document.title = appMetaTitle;
  if (store.getters.isAuthenticated) {
    next(urlFormatter.default);
    return;
  }
  next();
};

const routes = [
  {
    path: '/reset',
    name: 'reset',
    component: () => import('../views/ResetPasswordView.vue'),
    beforeEnter: loginMetaSettings,
  },
  {
    path: '/changepwd',
    name: 'changepwd',
    component: () => import('../views/ChangeTmpPasswordView.vue'),
    beforeEnter: loginMetaSettings,
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue'),
    beforeEnter: loginMetaSettings,
  },

  {
    path: '/',
    name: 'Home',
    component: Home,
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/badges',
    name: 'Badges',
    component: () => import('../views/Badge.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.badgeUserList,
    name: 'BadgesUsers',
    component: () => import('../views/BadgeUser.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.badgeUserFormNew,
    name: 'BadgesUsersFormNew',
    component: () => import('../views/BadgeUserForm.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.badgeUserFormEdit(':id'),
    name: 'BadgesUsersFormEdit',
    component: () => import('../views/BadgeUserForm.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import('../views/Company.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.doorList,
    name: 'Doors',
    component: () => import('../views/Door.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.doorListCollective,
    name: 'CollectiveDoors',
    component: () => import('../views/Door.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.doorListOffice,
    name: 'OfficeDoors',
    component: () => import('../views/Door.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/locations/zones',
    name: 'Zones',
    component: () => import('../views/Zone.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/stations',
    name: 'Stations',
    component: () => import('../views/Station.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.ruleList,
    name: 'Rules',
    component: () => import('../views/Rule.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.ruleFormNew,
    name: 'RulesFormNew',
    component: () => import('../views/RuleForm.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.ruleFormEdit(':id'),
    name: 'RulesFormEdit',
    component: () => import('../views/RuleForm.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.stationLocationList,
    name: 'StationsLocations',
    component: () => import('../views/StationLocation.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.stationLocationFormNew,
    name: 'StationsLocationsFormNew',
    component: () => import('../views/StationLocationForm.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: urlFormatter.stationLocationFormEdit(':id'),
    name: 'StationsLocationsFormEdit',
    component: () => import('../views/StationLocationForm.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/User.vue'),
    beforeEnter: ifAuthenticated,
  },
  {
    path: '/whitelist',
    name: 'Whitelist',
    component: () => import('../views/Whitelist.vue'),
    beforeEnter: ifAuthenticated,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

AWSEventBus.$on('loggedIn', async (data) => {
  const user = {};
  user.email = data.signInUserSession.idToken?.payload?.email;
  user.id = data.signInUserSession.idToken?.payload['custom:unique-id'];
  user.role = data.signInUserSession.idToken?.payload['custom:role'];
  store.dispatch('setUser', user).then(() => {
    router.push(urlFormatter.default);
  });
});

AWSEventBus.$on('resetPassword', async (data) => {
  router.push({ path: '/reset', query: { username: data.username } });
});

AWSEventBus.$on('pwdChanged', async () => {
  router.push({ path: urlFormatter.default });
});

AWSEventBus.$on('changeTmpPassword', async (data) => {
  router.push({ path: '/changepwd', query: { username: data.username, password: data.password } });
});

export default router;

export {
  router,
  urlFormatter,
};
