// eslint-disable-next-line import/no-mutable-exports
let cognitoconfig = {};
if (process.env.NODE_ENV === 'production') {
  cognitoconfig = {
    aws_project_region: 'eu-west-3',
    aws_cognito_region: 'eu-west-3',
    aws_user_pools_id: 'eu-west-3_eMm24kIZc',
    aws_user_pools_web_client_id: '7ieboh7tglvkc1pfiad22vhm5s',
  };
} else {
  cognitoconfig = {
    aws_project_region: 'eu-west-3',
    aws_cognito_region: 'eu-west-3',
    aws_user_pools_id: 'eu-west-3_OlvSAlRQa',
    aws_user_pools_web_client_id: '4o0ebb16qn7o84ruikmkppts2f',
  };
}

export default cognitoconfig;
