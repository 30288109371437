const roleAdmin = 'admin';
const roleSuperAdmin = 'superAdmin';

const role = [roleAdmin, roleSuperAdmin];

const defaultCrudAccess = {
  read: false,
  readInactive: false,
  create: false,
  update: false,
  delete: false,
};

function getAllowedCrudForUser(crudAllowedRoles, userRoles) {
  const result = { ...defaultCrudAccess };
  userRoles.forEach((userRole) => {
    if (crudAllowedRoles.read.find((allowed) => allowed === userRole)) result.read = true;
    if (crudAllowedRoles.readInactive.find((allowed) => allowed === userRole)) {
      result.readInactive = true;
    }
    if (crudAllowedRoles.create.find((allowed) => allowed === userRole)) result.create = true;
    if (crudAllowedRoles.update.find((allowed) => allowed === userRole)) result.update = true;
    if (crudAllowedRoles.delete.find((allowed) => allowed === userRole)) {
      result.delete = true;
    }
  });
  return result;
}

function isFieldAllowedForRolesRead(field, userRoles) {
  let found = false;
  field.allowedRoles.read.forEach((fr) => {
    if (userRoles.find((ur) => ur === fr)) found = true;
  });
  return found;
}

function isFieldAllowedForRolesWrite(field, userRoles) {
  let found = false;
  field.allowedRoles.write.forEach((fr) => {
    if (userRoles.find((ur) => ur === fr)) found = true;
  });
  return found;
}

function isFieldAllowedForRoles(field, userRoles) {
  const found = isFieldAllowedForRolesRead(field, userRoles);
  if (found) return true;
  return isFieldAllowedForRolesWrite(field, userRoles);
}

export {
  role,
  roleAdmin,
  roleSuperAdmin,
  defaultCrudAccess,
  getAllowedCrudForUser,
  isFieldAllowedForRoles,
  isFieldAllowedForRolesRead,
  isFieldAllowedForRolesWrite,
};
