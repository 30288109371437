import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { roleAdmin, roleSuperAdmin } from '@/model/accessRolesModel';
import { getMenuItems } from '@/app_data/MainMenu';
import i18n from '../i18n';

Vue.use(Vuex);

export const INITIAL_STATE = {
  user: undefined,
  userMenuItems: getMenuItems(),
  headerTitle: i18n.t('application.defaultTitle'),
  isAdmin: false,
  isSuperAdmin: false, // The profile of the user includes SuperAdmin
  isSuperAdminEnabled: false, // enable a user which isSuperAdmin role to disable this role.
  snackbarInfo: {},
};

const ROLE_LABEL_ADMIN = 'ADMIN';

function filterAccessibleItems(items, roles) {
  return items.filter((item) => {
    let result = false;
    roles.forEach((role) => {
      if (item?.authorizedRoles.indexOf(role) > -1) { result = true; }
    });
    if (item?.children) {
      /* eslint-disable no-param-reassign */
      item.children = filterAccessibleItems(item.children, roles);
      /* eslint-enable no-param-reassign */
    }
    return result;
  });
}

function filterDisplayedItems(items) {
  const resultItems = [];
  items.forEach((item) => {
    const localItem = { ...item };
    if (!item?.isNotDisplayed === true) {
      if (item?.children) {
        /* eslint-disable no-param-reassign */
        localItem.children = filterDisplayedItems(item.children);
        /* eslint-enable no-param-reassign */
      }
      resultItems.push(localItem);
    }
  });
  return resultItems;
}

function translateItemNames(items, locale) {
  // Apply i18n locale translation to any "name" properties
  // (current array and descendant objects stored in a "children" property).
  const result = [];
  items.forEach((item) => {
    const localItem = { ...item };
    if (item.name) {
      localItem.name = i18n.t(item.name, locale);
    }
    if (localItem.children && Array.isArray(localItem.children)) {
      localItem.children = translateItemNames(localItem.children, locale);
    }
    result.push(localItem);
  });
  return result;
}

function userIsAdmin(state) {
  const result = state?.user?.role === ROLE_LABEL_ADMIN;
  state.isAdmin = result;
  return result;
}

function userIsSuperAdmin(state) {
  const result = state?.user?.role === ROLE_LABEL_ADMIN
    && (state?.user?.email.endsWith('@bag-era.fr'));
  state.isSuperAdmin = result;
  return result;
}

function userGetRoles(state) {
  const result = [];
  if (userIsAdmin(state)) { result.push(roleAdmin); }
  if (userIsSuperAdmin(state) && state.isSuperAdminEnabled) {
    result.push(roleSuperAdmin);
  }
  return result;
}

export default new Vuex.Store({

  plugins: [createPersistedState({})],

  state: () => ({ ...INITIAL_STATE }),

  getters: {
    user: (state) => state.user,
    userRoles: (state) => userGetRoles(state),
    isAdmin: (state) => userIsAdmin(state),
    isSuperAdmin: (state) => userIsSuperAdmin(state),
    isSuperAdminEnabled: (state) => state.isSuperAdminEnabled,
    isAuthenticated: (state) => state?.user !== undefined,
    userMenuItems: (state) => state.userMenuItems,
    userDisplayedMenuItems: (state) => filterDisplayedItems(state.userMenuItems),
    headerTitle: (state) => state.headerTitle,
    snackbarInfo: (state) => state.snackbarInfo,
  },

  mutations: {
    displaySnackbar(state, [message, isAlert]) {
      state.snackbarInfo = { message, isAlert };
    },

    refreshMenuItems(state, items) {
      if (items.length === 0) {
        let newItems = [];
        const roles = [];
        if (this.getters.isAdmin) { roles.push(roleAdmin); }
        if (this.getters.isSuperAdmin && this.getters.isSuperAdminEnabled) {
          roles.push(roleSuperAdmin);
        }
        // Only keep the authorized menu items
        newItems = filterAccessibleItems(getMenuItems(), roles);
        // Then translate the accessible items
        newItems = translateItemNames(newItems, this.getters.locale);
        state.userMenuItems = newItems;
      } else state.userMenuItems = items;
    },

    mutateIsSuperAdminEnabled: (state, value) => {
      if (state.isSuperAdmin) {
        state.isSuperAdminEnabled = value;
      }
    },

    updateUser: (state, value) => {
      state.user = value;
    },

    updateHeaderTitle(state, headerTitle) {
      const title = headerTitle || INITIAL_STATE.headerTitle;
      state.headerTitle = title;
    },

  },

  actions: {
    displayAlert({ commit }, message) {
      commit('displaySnackbar', [message, true]);
    },

    displayInfo({ commit }, message) {
      commit('displaySnackbar', [message, false]);
    },

    refreshMenuItems({ commit }, items) {
      commit('refreshMenuItems', items);
    },

    resetUserSession({ commit }) {
      commit('updateUser', undefined);
      commit('refreshMenuItems', []);
    },

    setHeaderTitle({ commit }, headerTitle) {
      commit('updateHeaderTitle', headerTitle);
    },

    setUser({ commit }, userValue) {
      commit('updateUser', userValue);
      commit('refreshMenuItems', []);
    },

    setIsSuperAdminEnabled({ commit }, [user, value]) {
      commit('mutateIsSuperAdminEnabled', value);
      commit('updateUser', user);
      commit('refreshMenuItems', []);
    },

  },
});
