import Vue from 'vue';
import {
  Login, ChangeTmpPassword, ResetPassword,
} from '@bag-era/cognito-authentication';
import Auth from '@aws-amplify/auth';
import App from './App.vue';
import './registerServiceWorker';
import { router } from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import axiosInstance from './api/utils';
// import feather from 'vue-icon';    // TODO : restore if needed ...or not.
// import VueApexCharts from 'vue-apexcharts';  // TODO : restore if needed ...or not.

function resetUserSession() {
  store.dispatch('resetUserSession').then(() => {
    router.push('/login');
  });
}

axiosInstance.interceptors.request.use((config) => Auth.currentSession()
  .then((session) => {
    /* eslint-disable no-param-reassign */
    // User is logged in. Set auth header on all requests
    config.headers.Authorization = session.idToken.jwtToken;
    if (config?.url && config.url.startsWith('/whitelists')) {
      config.headers['x-api-key'] = '6z2YbVG2gsLagrAkyrVo1LZS4x5aoBP1sRSaCpJ9';
    }
    config.params = config.params || {};
    /* eslint-enable no-param-reassign */
    return Promise.resolve(config);
  })
  .catch(() => {
    // No logged-in user: don't set auth header - got to login page
    resetUserSession();
  }));

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    const shouldRestart = (error?.message === 'Network Error')
      || (error?.response?.status === 401);
    // In case of network error (supposedly an Unauthgorized request
    // due to an expired JWT in an open browser tab), reset session and raise exception
    if (shouldRestart) {
      const exception = error; // copy the error to complement the message
      exception.message = ` ${exception.message}.\nPlease, ensure your connection is up, then retry.`;
      // resetUserSession(); TODO : restore this !
      throw exception;
    }
    throw error;
  },
);

Vue.config.productionTip = false;
Vue.use(Auth);
Vue.use(Login, ChangeTmpPassword, ResetPassword);
// Vue.use(VueI18n); // TODO : restore if needed ...or not.
// Vue.use(feather, 'v-icon');
// Vue.use(VueApexCharts);
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
