<template>
  <v-app id="inspire">
    <v-navigation-drawer
      v-show="isAuthenticated"
      v-model="drawer"
      app
      width="350"
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            Y&middot;Spot Village
          </v-list-item-title>
          <v-list-item-subtitle>
            Version 3.0.6.{{ environmentStage }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <CompanyTreeview></CompanyTreeview>

    </v-navigation-drawer>

    <v-app-bar
      v-show="isAuthenticated"
      app
      :color="appBarColor"
      dark
    >
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Village &middot; Y&middot;Spot &middot; {{headerTitle}}</v-toolbar-title>

      <v-spacer></v-spacer>
      <v-switch
        v-if="isSuperAdmin"
        class="pt-6"
        v-model="superAdminEnabled"
        :label="isSuperAdminEnabledLabel"
        >
      </v-switch>

      <v-btn icon>
         <v-icon @click="logoutUser">mdi-logout</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-snackbar
        multi-line
        content-class="snack"
        v-model="snackbarDisplay"
        :color="snackbarColor"
        :top="!snackbarIsError"
        :centered="snackbarIsError"
        :outlined="!snackbarIsError"
        :timeout="snackbarTimeout"
        >
        <span v-html="snackbarMessage">
        </span>
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            v-bind="attrs"
            @click="snackbarDisplay = false"
          >
            X
          </v-btn>
        </template>
      </v-snackbar>

      <router-view></router-view>

    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';
import CompanyTreeview from '@/components/CompanyTreeview.vue';
import i18n from '@/i18n';

export default {

  components: {
    CompanyTreeview,
  },

  data: () => ({
    drawer: null,

    options: [
      { title: 'Click Me', icon: 'mdi-logout' },
    ],

    snackbarDisplay: false,
    snackbarTimeout: 2500,
    snackbarMessage: '',
    snackbarColor: 'success',
    snackbarIsError: true,
  }),

  watch: {
    snackbarInfo(newValue) {
      if (newValue.message) {
        this.displaySnackbar(newValue.message, newValue.isAlert);
      } else {
        this.snackbarDisplay = false;
      }
    },
  },

  computed: {
    ...mapGetters([
      'headerTitle',
      'isAuthenticated',
      'isSuperAdmin',
      'isSuperAdminEnabled',
      'snackbarInfo',
      'user',
    ]),

    appBarColor() {
      if (process.env.NODE_ENV === 'production') return 'indigo darken-2';
      return 'blue-grey';
    },

    environmentStage() {
      return process.env.NODE_ENV[0];
    },

    superAdminEnabled: {
      get() {
        return this.isSuperAdminEnabled;
      },
      set() {
        this.$store.dispatch('setIsSuperAdminEnabled', [this.user, !this.isSuperAdminEnabled]);
        this.$router.go();
      },
    },

    isSuperAdminEnabledLabel() {
      if (this.isSuperAdminEnabled) return i18n.t('userRole.isSuperAdminEnabled');
      return i18n.t('userRole.isSuperAdminDisabled');
    },

  },

  methods: {

    displaySnackbar(message, isAlert) {
      this.snackbarMessage = message.replaceAll('\n', '<br>');
      if (isAlert && isAlert === true) {
        this.snackbarColor = 'error';
        this.snackbarTimeout = -1;
        this.snackbarIsError = true;
      } else {
        this.snackbarColor = 'info';
        this.snackbarTimeout = 3000;
        this.snackbarIsError = false;
      }
      this.snackbarDisplay = true;
    },

    logoutUser() {
      localStorage.clear();
      this.$store.dispatch('resetUserSession').then(() => {
        window.location.reload();
      });
    },
  },
};
</script>

<style>
:root{
  --login-main-color:#0f3877;
  --login-main-bg-color:#8eabeb;
}

/* input {
  border-bottom: 1px solid lightgray !important;
} */
</style>
