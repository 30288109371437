import { roleAdmin, roleSuperAdmin } from '../model/accessRolesModel';

const MAIN_MENU_ITEMS = [
  {
    id: 1,
    name: 'mainMenuItems.administration.company',
    ico: 'factory',
    authorizedRoles: [roleAdmin, roleSuperAdmin],
    route: '/companies',
    parentIds: [1],
  },
  {
    id: 2,
    name: 'mainMenuItems.administration.users',
    ico: 'account',
    authorizedRoles: [roleAdmin, roleSuperAdmin],
    route: '/users',
    parentIds: [2],
  },
  {
    id: 3,
    name: 'mainMenuItems.door.root',
    ico: 'door',
    authorizedRoles: [roleAdmin, roleSuperAdmin],
    route: '/locations/doors',
    parentIds: [3],
    children: [
      {
        id: 31,
        name: 'mainMenuItems.door.collective',
        ico: 'door-sliding',
        authorizedRoles: [roleAdmin, roleSuperAdmin],
        route: '/locations/doors/collective',
        parentIds: [3],
      },
      {
        id: 32,
        name: 'mainMenuItems.door.office',
        ico: 'door-closed',
        authorizedRoles: [roleAdmin, roleSuperAdmin],
        route: '/locations/doors/office',
        parentIds: [3],
      },
    ],
  },
  {
    id: 4,
    name: 'mainMenuItems.rules.root',
    ico: 'door-closed-lock',
    authorizedRoles: [roleAdmin, roleSuperAdmin],
    route: '/rules',
    parentIds: [4],
  },
  {
    id: 5,
    name: 'mainMenuItems.administration.root',
    ico: 'console',
    authorizedRoles: [roleSuperAdmin],
    children: [
      {
        id: 51,
        name: 'mainMenuItems.administration.badge',
        ico: 'card-account-details-outline',
        authorizedRoles: [roleSuperAdmin],
        route: '/badges',
        parentIds: [5],
      },
      {
        id: 52,
        name: 'mainMenuItems.administration.zone',
        ico: 'image-filter-center-focus',
        route: '/locations/zones',
        authorizedRoles: [roleSuperAdmin],
        parentIds: [5],
      },
      {
        id: 53,
        name: 'mainMenuItems.administration.station',
        ico: 'radio-tower',
        route: '/stations',
        authorizedRoles: [roleSuperAdmin],
        parentIds: [5],
      },
    ],
  },
  {
    id: 6,
    name: 'mainMenuItems.dev.root',
    ico: 'shovel',
    authorizedRoles: [roleSuperAdmin],
    children: [
      {
        id: 61,
        name: 'mainMenuItems.dev.stationlocation',
        ico: 'target',
        authorizedRoles: [roleSuperAdmin],
        route: '/stationslocations',
        parentIds: [6],
      },
      {
        id: 62,
        name: 'mainMenuItems.dev.badgeuser',
        ico: 'sticker-emoji',
        authorizedRoles: [roleAdmin, roleSuperAdmin],
        route: '/badgesusers',
        parentIds: [6],
      },
      {
        id: 99,
        name: 'mainMenuItems.dev.whitelist',
        ico: 'format-list-checks',
        authorizedRoles: [roleSuperAdmin],
        route: '/whitelist',
        parentIds: [6],
      },
    ],
  },
];

function getMenuItems() {
  return JSON.parse(JSON.stringify(MAIN_MENU_ITEMS));
}

export default MAIN_MENU_ITEMS;
export {
  MAIN_MENU_ITEMS,
  getMenuItems,
};
