<template>
  <v-card
    class="mx-auto"
    elevation="0"
    max-width="600"
  >
    <!-- Main Treeview -->
    <v-card-text >
      <v-treeview
        dense
        activatable
        open-on-click
        transition
        return-object
        :active.sync="active"
        :items="userDisplayedMenuItems"
        :search="search"
        :open.sync="open"
        color="info"
        @update:active="select"
      >
        <template v-slot:label="{ item }" >
            {{ item.name }}
        </template>
        <template v-slot:prepend="{ item }">
          <v-icon
            v-text="`mdi-${item.ico}`"
            @click="select(item)"
          ></v-icon>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data: () => ({
    active: [],
    caseSensitive: false,
    open: [],
    routeSelectedTo: '',
    search: null,
  }),

  created() {
    this.$store.dispatch('refreshMenuItems', []);
  },

  watch: {
    $route(to) {
      this.routeSelectedTo = to.path;
      this.syncDisplayWithRouteSelectedTo();
    },

    // userMenuItems(newVal) {
    //   console.log('newVal', newVal);
    // },
  },

  computed: {
    ...mapGetters([
      'userMenuItems',
      'userDisplayedMenuItems',
    ]),

    items: {
      get() {
        const result = this?.userMenuItems ?? [];
        return result;
      },
      set(items) {
        this.$store.dispatch('refreshMenuItems', items);
      },
    },
  },

  methods: {
    buildHeaderTitle(activeItem) {
      let result = '';
      if (activeItem) {
        result = activeItem?.name ?? '';
        if (activeItem?.parentIds && activeItem.parentIds.length > 0) {
          activeItem.parentIds.forEach((id) => {
            const localParent = this.findItemById(id);
            if (localParent) {
              result = `${localParent.name} - ${result}`;
            }
          });
        }
      }
      return result;
    },

    findItemById(itemId) {
      const stack = [...this.items]; // Deep copy
      let node = null;
      let childIndex;
      while (stack.length > 0) {
        node = stack.pop();
        if (node.id && node.id === itemId) {
          // Found it!
          return node;
        }
        if (node.children && node.children.length) {
          for (childIndex = 0; childIndex < node.children.length; childIndex += 1) {
            stack.push(node.children[childIndex]);
          }
        }
      }
      // Didn't find it. Return null.
      return null;
    },

    findItemByRoute(itemRoute) {
      const stack = [...this.items]; // Deep copy
      let node = null;
      let childIndex;
      while (stack.length > 0) {
        node = stack.pop();
        if (node.route && node.route === itemRoute) {
          // Found it!
          return node;
        }
        if (node.children && node.children.length) {
          for (childIndex = 0; childIndex < node.children.length; childIndex += 1) {
            stack.push(node.children[childIndex]);
          }
        }
      }
      // Didn't find it. Try to remove last element of the path
      const pathItems = itemRoute.split('/').filter((item) => item.length > 0);
      if (pathItems.length > 1) {
        pathItems.splice(-1, 1);
        const truncatedRoute = `/${pathItems.join('/')}`;
        const result = this.findItemByRoute(truncatedRoute);
        // console.log('truncatedRoute, result', truncatedRoute, result);
        return result;
      }
      // Didn't find it. Return null.
      return null;
    },

    select(item) {
      if (item.length > 0 && item[0].route && item[0].route.length > 0) {
        // avoid router NavigationDuplicated exceptoin
        if (this.$router.currentRoute.path !== item[0].route) {
          if (this.$router?.options?.routes?.length > 0) {
            const newRoute = this.$router.options.routes.find(
              (rte) => rte.path === item[0].route,
            );
            if (newRoute && newRoute.name) {
              this.$router.push({ name: newRoute.name, replace: true });
            } else this.$router.push({ path: item[0].route, replace: true });
          } else {
            this.$router.push({ path: item[0].route, replace: true });
          }
          this.$store.dispatch('setHeaderTitle', item[0].name);
        }
      }
      const items = this?.userMenuItems ?? [];
      this.items = items;
    },

    syncDisplayWithRouteSelectedTo() {
      this.active = [];
      this.open = [];
      const activeItem = this.findItemByRoute(this.routeSelectedTo);
      if (activeItem) {
        this.active = [activeItem];
        this.$store.dispatch('setHeaderTitle', this.buildHeaderTitle(activeItem));
        if (activeItem.parentIds) {
          activeItem.parentIds.forEach((id) => {
            const localParent = this.findItemById(id);
            if (localParent) {
              this.open.push(localParent);
            }
          });
        }
      }
    },
  },
};
</script>
